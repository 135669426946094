import { lazy } from 'react';
import WidgetCategory from '../../WidgetCategory';
import PublishUtils from '../../../helpers/PublishUtils';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../ui-primitives/tabs'
import { JsonTextEditor } from '../../JsonEditor'

export default class IFCJSViewerV3Descriptor {

  static getDescription() {
    return {
      name: 'IFCJSViewerV3',
      component:  lazy(() =>  import('./IFCJSViewerV3')), 
      label: WidgetCategory.experimental+' IFC Viewer V3 (BIM-Legal development) ',
      category:WidgetCategory.experimental,
      variables: [

        {
          name: 'modus',
          type: 'select',
          label: 'modus',
          options: [
            {
              label: 'Viewer',
              value: 'Viewer',
            },
            {
              label: 'Upload only',
              value: 'Upload',
            },
            {
              label: 'Viewer with Upload',
              value: 'ViewerWithUpload',
            },
            {
              label: 'Download BIM-Legal file',
              value: 'BIMLegalDownload',
            },
          ],
        },
        {
          name: 'uploadFileMode',
          type: 'select',
          label: 'upload File Mode',
          options: [
            {
              label: 'single Ifc',
              value: 'singleIfc',
            },
            {
              label: 'multiple Ifc  (not yet implemented)',
              value: 'multipleIfc',
            },
          ],
        },
        {
          name: 'uploadButtonLabel',
          type: 'text',
          label: 'Text for the upload button',
        },
        {
          name: 'uploadPanelLabel',
          type: 'text',
          label: 'Text for the upload panel',
        },
        {
          name: 'uploadBelongsTo',
          type: 'text',
          label: 'uri for belongsTo parameter',
        },
        {
          name: 'uploadconversionjson',
          type: 'json',
          label: 'conversion to LD options in JSON',
          helpComponent: UploadConversionJsonHelp
        },
        PublishUtils.getTimestampParametersDef(),
        PublishUtils.getRunRuleParametersDef(),
             

        {
          name: 'files prefix',
          type: 'text',
          label: 'prefix for all ifc files (e.g. http://wistor.nl/servlets/cgi/download/repo/) ',
        },

        

        {
          name: 'ifcfiles',
          type: 'array',
          label: 'comma separated url(s) to IFC files to display. parameters can be used here referring to (wistor) download locations using the wistor download servlet',
        },
       
        {
          name: 'Visualisation mode',
          type: 'select',
          label: '2d of 3d modus',
          options: [
            {
              label: '3D',
              value: '3D',
            },
            {
              label: '2D',
              value: '2D',
            }
          ]},
        {
          name: 'Object visualisation mode',
          type: 'select',
          label: 'options to visualise IFC data icw Sparql results',
          options: [
            {
              label: 'show only sparql results ',
              value: 'sparqlonly',
            },
            {
              label: 'show all transparent (sparql results in color) ',
              value: 'transparent',
            },
            {
              label: 'normal (no sparql necessary)',
              value: 'normal',
            }
            
          ]},
      
      
        {
          name: 'query',
          type: 'yasgui',
          label: 'use parameter ?guid (necessary to link?) ?uri (handy for publishing) ?hidden (false/true) ?alpha ?color (create a color based upon this value) ?label (popup label)  ',
          defaultQuery:""
        },
        {
          name: 'publishVariable',
          type: 'text',
          label: 'sparql parameter name for publishing select event result. Arguably we need GUIDS to link data to the viewer. Perhaps GUID based interaction (publish guids instead of URIs is handier)',
        },
        {
          name: 'subscribeVariable',
          type: 'text',
          label: 'Published variabele on which to listen for selection.',
        },
        {
          name: 'multipleselect',
          type: 'boolean',
          label: 'allow multiple selections. parameters are filled as comma separated values',
        },
       
        PublishUtils.getMultipleValueFormatOptions(),
   
        
        /*,
    {
      name: 'clearParameter',
      type: 'text',
      label: 'clears widget when parameter changes'
    }*/
      ],
    }
  }
}

const UPLOADCONVERSIONJSON_DEFAULT = {
  "categoriesToInclude": [
    "IFCELEMENT", "IFCSITE", "IFCPROJECT", "IFCBUILDING", "IFCBUILDINGSTOREY", "IFCGROUP", "IFCZONE"
  ],
  "relationsToInclude": [
    "IsDefinedBy",
    "ContainedInStructure",
    "ContainsElements",
    "Decomposes",
    "IsDecomposedBy",
    "IsGroupedBy",
    "HasAssignments",
  ],
  "includeElementsWithoutRelations": true,
  "psetNameRegex": null
}

const UPLOADCONVERSIONJSON_EVERYTHING = {
  "categoriesToInclude": [
    "IFCELEMENT", "IFCSITE", "IFCPROJECT", "IFCBUILDING", "IFCBUILDINGSTOREY", "IFCGROUP"
  ],
  "relationsToInclude": [
    "IsDecomposedBy",
    "Decomposes",
    "AssociatedTo",
    "HasAssociations",
    "ClassificationForObjects",
    "IsGroupedBy",
    "HasAssignments",
    "IsDefinedBy",
    "DefinesOcurrence",
    "IsTypedBy",
    "Types",
    "Defines",
    "ContainedInStructure",
    "ContainsElements",
    "HasControlElements",
    "AssignedToFlowElement",
    "ConnectedTo",
    "ConnectedFrom",
    "ReferencedBy",
    "Declares",
    "HasContext",
    "Controls",
    "IsNestedBy",
    "Nests"
  ],
  "includeElementsWithoutRelations": true,
  "psetNameRegex": null
}

const UPLOADCONVERSIONJSON_PSETS = {
  "categoriesToInclude": [
    "IFCELEMENT"
  ],
  "relationsToInclude": [
    "IsDefinedBy",
  ],
  "includeElementsWithoutRelations": false,
  "psetNameRegex": null
}

const UPLOADCONVERSIONJSON_BIMLEGAL = {
  "categoriesToInclude": [
    "IFCSPACE", "IFCSITE", "IFCPROJECT", "IFCBUILDING", "IFCBUILDINGSTOREY"
  ],
  "relationsToInclude": [
    "IsDefinedBy",
    "ContainsElements",
    "ContainedInStructure"
  ],
  "includeElementsWithoutRelations": true,
  "psetNameRegex": null
}

function UploadConversionJsonHelp() {
  return (
    <div>
      <h3 className="mb-2 font-bold">Configuration properties</h3>
      <ul className="mb-3">
        <li>
          <code>categoriesToInclude</code>: list of IFC object categories to include in export,
          like <code></code>. IFCELEMENT is a shortcut for all categories that have{' '}
          <a
            href="https://standards.buildingsmart.org/IFC/RELEASE/IFC4_1/FINAL/HTML/schema/ifcproductextension/lexical/ifcelement.htm"
            rel="noopener noreferrer" target="_blank" style={{ textDecoration: 'underline' }}>IfcElement</a> as
          superclass.
          Should be in UPPERCASE. If this is not set or null, all non-orphaned elements are included.
        </li>
        <li>
          <code>relationsToInclude</code>: defines relations to include (according the relation name in IFC).{' '}
          Is case sensitive. Use IsDefinedBy for property set relations.{' '}
          If this is not set or null, all supported relations are included.
        </li>
        <li>
          <code>includeElementsWithoutRelations</code>: boolean whether to include elements where zero relations where found according to
          the relationsToInclude & psetNameRegex settings.
        </li>
        <li>
          <code>psetNameRegex</code>: regex to only export property sets relations with a specific name. If this is not set, null,
          or empty, all property sets are included.
        </li>
      </ul>

      <h3 className="mb-2 font-bold">Example configurations</h3>
      <Tabs defaultValue="default">
        <TabsList>
          <TabsTrigger value="default">Default</TabsTrigger>
          <TabsTrigger value="everything">Everything</TabsTrigger>
          <TabsTrigger value="elements-psets">IfcElements with psets</TabsTrigger>
          <TabsTrigger value="bimlegal">BIMLegal</TabsTrigger>
        </TabsList>
        <TabsContent value="default">
          <JsonTextEditor value={JSON.stringify(UPLOADCONVERSIONJSON_DEFAULT, null, 4)} readonly />
        </TabsContent>
        <TabsContent value="everything">
          <JsonTextEditor value={JSON.stringify(UPLOADCONVERSIONJSON_EVERYTHING, null, 4)} readonly />
        </TabsContent>
        <TabsContent value="elements-psets">
          <JsonTextEditor value={JSON.stringify(UPLOADCONVERSIONJSON_PSETS, null, 4)} readonly />
        </TabsContent>
        <TabsContent value="bimlegal">
          <JsonTextEditor value={JSON.stringify(UPLOADCONVERSIONJSON_BIMLEGAL, null, 4)} readonly />
        </TabsContent>
      </Tabs>
    </div>
  )
}