import { useEffect, useState } from 'react'
import Yasqe from '@triply/yasqe'
import '../../node_modules/@triply/yasgui/build/yasgui.min.css'
import '../../node_modules/@triply/yasqe/build/yasqe.min.css'

export function Yasgui(props: {
  value: string
  onBlur: (value: string) => void
  mode: 'update-value' | 'init-value'
}) {
  const [yas, setYas] = useState<Yasqe | null>(null)
  const [ref, setRef] = useState<any>(null)

  useEffect(() => {
    if (!ref || yas) return
    const newYas = new Yasqe(ref)
    newYas.setValue(props.value || '')
    newYas.on('blur', () => props.onBlur(newYas.getValue()))
    setYas(newYas)
  }, [ref, yas, props])

  useEffect(() => {
    if (!yas || props.mode === 'init-value') return
    yas.setValue(props.value)
  }, [yas, props.value, props.mode])

  return <div ref={setRef} />
}
