import { PublishedDict } from "../redux/pubsub"
import PublishUtils from "./PublishUtils"
import { OpaqueSparqlResults } from "./sparqlResultHelpers"

/**
 * Data-structure of a configuration-item
 */
type ConfigDescription<
  T extends ConfigVarTypename,
  TOptions extends ConfigOptions,
  TName extends string = string
> = {
  name: TName
  type: T
  label: string
  options?: TOptions
}

type ConfigVarTypename = 'select' | 'boolean' | 'array' | 'json' | 'yasgui' | 'javascript' | 'button' | 'text'
type ConfigOptions = PresentConfigOptions | undefined
type PresentConfigOptions = ReadonlyArray<{ readonly label: string, readonly value: string }>

/**
 * React-props that every widget gets
 */
export type BaseWidgetProps = {
  onChange: any
  withinSelectedTab?: null | boolean
  panelstyle: string
  mode?: null | 'edit'
  pubsub: PublishedDict
  publish: (topic: string, value: unknown) => void
}

/**
 * React-props that every sparql-widget gets
 */
export type SparqlWidgetProps = {
  data?: OpaqueSparqlResults
}

/**
 * React-props that every area-widget gets
 */
export type AreaWidgetProps = { areas: {}[] }

/**
 * React-props from configuration-descriptions
 */
export type ConfigurablePropsFromDescription<
  T extends ReadonlyArray<ConfigDescription<any, any>>
> =
  ObjectFrom<ConfigNameTuple<T>, ConfigTypeTuple<T>>

/**
 * An object from two tuples "zipped" together: a tuple containing keys and a tuple containing types
 * source: https://stackoverflow.com/a/70398429
 */
type ObjectFrom<
  N extends Record<keyof TupleToObject<T>, PropertyKey>,
  T extends readonly any[],
> =
  { [K in keyof TupleToObject<T> as N[K]]: T[K] };

/**
 * A tuple typed as an object (by omitting keys that are commonly found in arrays)
 * source: https://stackoverflow.com/a/70398429
 */
type TupleToObject<T extends readonly any[]> = Omit<T, keyof any[]>

/**
 * A tuple of typenames from configuration-items
 */
type ConfigNameTuple<T extends ReadonlyArray<ConfigDescription<any, any>>> = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [K in keyof T]: T[K] extends ConfigDescription<infer _Typename, infer _Options, infer Name>
    ? Name
    : never
}

/**
 * a tuple of types from configuration-items
 */
type ConfigTypeTuple<T extends ReadonlyArray<ConfigDescription<any, any>>> = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [K in keyof T]: T[K] extends ConfigDescription<infer Typename, infer Options, infer _Name>
    ? ConfigurablePropType<Typename, Options>
    : never
}

/**
 * Type from typename
 */
type ConfigurablePropType<T extends ConfigVarTypename, TOptions extends ConfigOptions> =
  T extends 'array' ? (null | undefined | string[]) :
  T extends 'boolean' ? (null | undefined | boolean) :
  T extends 'json' ? (null | undefined | {} | []) :
  T extends 'select' ? (null | undefined | (TOptions extends PresentConfigOptions ? TOptions[number]['value'] : never)) :
  T extends 'text' ? (null | undefined | string) :
  never

type PublishedState = Record<string, any>

type SubscriptionState<T> =
  { isValid: false, hasChanged: boolean, current: unknown, topic: null | undefined | string } |
  { isValid: true, hasChanged: boolean, current: T, topic: string }

type SubscriptionTransformation<T> = { success: false }| { success: true, value: T }

export function getSubscription<T>(
  previousState: null | undefined | PublishedState,
  currentState: PublishedState,
  topic: null | undefined | string,
  transform: (x: unknown) => SubscriptionTransformation<T>
): SubscriptionState<T> {
  if (!topic) return { isValid: false, hasChanged: false, current: undefined, topic }

  const previous = previousState?.[topic]
  const current = currentState?.[topic]
  const hasChanged = previous !== current && current != null
  const transformation = transform(current)
  if (!transformation.success) return { isValid: false, hasChanged, current, topic }

  return {
    isValid: transformation.success,
    hasChanged: previous !== current && current != null,
    current: transformation.value,
    topic,
  }
}

export function isValidIndex(x: unknown): SubscriptionTransformation<number> {
  const str = String(x)
  const number = Number(x)
  const int = parseInt(str, 10)
  const isNotInt = int !== number
  if (x == null || Number.isNaN(number) || isNotInt) return { success: false }
  return { success: true, value: int }
}

export function isString(x: unknown): SubscriptionTransformation<string> {
  return typeof x !== 'string'
    ? { success: false }
    : { success: true, value: x }
}

export function interpolateSubscriptions(
  widget: {props: { pubsub: Record<string, unknown>}},
  strTemplate: null | undefined | string,
  outputFormat: 'boolean',
) {
    if (outputFormat === 'boolean') {
      const interpolated = PublishUtils.processStringForParameters(widget, strTemplate)
      return String(interpolated).toLowerCase() === 'true'
    }
    throw new Error('not implemented')
  }
