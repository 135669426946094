import PublishUtils from "../../../helpers/PublishUtils"
import { ConfigurablePropsFromDescription } from "../../../helpers/widget"

const defaultQuery = `
select * where {
    VALUES (?user ?user_ICON ?user_URL ?user_ICONSIZE ?name ?msg ?msg_URI ?msg_URL) {
        ('Sander' 'fa-face-rolling-eyes' 'https://bim-connected.slite.com/app/docs/uOj8o7XyCZx448' '2rem' 'Sander' 'Hoi' 'https://google.com' undef)
        ('Norent' 'fa-person-falling-burst' 'https://bim-connected.slite.com/app/docs/-r18oHM4poM-WX' '2rem' 'Norent' 'Hiiii' undef 'https://bing.com')
    }
}
`
type PublishVariableAction = {
  type: "publish"
  topic: string
  value: string
}

type StartRuleAction = {
  type: "start-rule"
  startRule: string
  parameters: Record<string, string> | null | undefined
  timestampVariable?: string
}

export type Action = PublishVariableAction | StartRuleAction

type ColumnButton = {
  icon?: string
  label?: string
  actions?: Array<Action>
  buttons?: ColumnButton[]
}

export type ColumnDescription = {
  id: string
  label: string
  filterable?: boolean
  sortable?: boolean
  hidden?: boolean
  publishTopic?: string
  buttons?: ColumnButton[]
  width?: string
}

export type ColumnConfig = ColumnDescription[]

export const configOptions = [
  {
    name: 'query',
    type: 'yasgui',
    description:"extra documentation",
    defaultQuery,
    label: [
      'Query-variable-names structured as "?{identifier}_{modifier}" modify',
      'the display query-variable-results of "?{identifier}",',
      'see help for more info',
    ].join(' '),
    helpComponent: QueryHelp
  },
  {
    name: 'publishVariable',
    type: 'text',
    label: 'Topic (variable) in which item URI is published when a link in the table is clicked. Can be overridden by the column-configuration\'s "publishTopic".',
    description: "more information about the publish variable"
  },
  {
    name: 'hideColumnHeaders',
    type: 'boolean',
    label: 'hide column headers',
  },
  {
    name: 'showFilters',
    type: 'boolean',
    label: 'enable global filter',
  },
  {
    name: 'idToFilterTextOf',
    type: 'text',
    label: 'Limit global filtering to specific identifier(s), separate with comma. Leave empty to filter all fields.',
  },
  {
    name: 'paginationEnabled',
    type: 'boolean',
    label: 'enable pagination',
  },
  {
    name: 'paginationPageSize',
    type: 'number',
    label: 'maximum number of rows to show per page',
  },
  {
    name: 'selectionMode',
    type: 'select',
    options: [
      { value: "DISABLED", label: "Disabled"},
      { value: "SINGLE", label: "Single row (highlight)"},
      { value: "MULTIPLE", label: "Multiple rows (checkboxes)"},
    ],
    label: 'row selection mode',
  },
  {
    name: 'variableToPublishOnSelection',
    type: 'text',
    label: 'Variable name from the SPARQL query representing the value for selection. Will be used for the selection publish and subscription settings below.'
  },
  {
    name: 'selectedRowSubscription',
    type: 'text',
    label: 'Variabele to listen to for row selection.',
  },
  {
    name: 'lastSelectedRowTopic',
    type: 'text',
    label: 'Topic to publish last selected row to.'
  },
  {
    name: 'selectedRowsTopic',
    type: 'text',
    label: 'Topic to publish selected rows to.'
  },
  PublishUtils.getMultipleValueFormatOptions(),
  {
    name: 'enableMockActions',
    type: 'boolean',
    label: 'enableMockActions',
  },
  {
    name: 'columnConfig',
    type: 'json',
    label: 'column config',
    helpComponent: ConfigJsonHelp
  }
] as const

function QueryHelp() {
  return (
    <div>
      <p className="mb-3">
        The results of this query are displayed in the table in the same order
        as they are returned from the triplestore.
      </p>
      <p className="mb-3">
        It is possible to modify a cell by selecting sparql-variables with the
        following name-shape <code>{'?{identifier}_{modifier}'}</code>.
        Rows have an empty identifier, e.g. <code>{'?_{modifier}'}</code>.
      </p>
      <p>
         The following <code>{'modifier'}</code>s have been implemented:
      </p>
      <ul className="list-disc pl-4">
        <li>
          <code>URI</code>: makes the cell clickable. On click it publishes this value to the global "publish topic",
          or the column-configuration-specific `publishTopic` (the latter overrides the former).{" "}
          <em>If this modifier is used, then <code>URL</code> should not be used</em> as you cannot have both behaviours.{" "}
          An error-notification is clearly visible on your screen if both modifiers are active.
        </li>
        <li>
          <code>URL</code>: makes the cell clickable. On click it opens a new browser-tab/window with this value as its URL.{" "}
          <em>If this modifier is used, then <code>URL</code> should not be used</em> as you cannot have both behaviours.{" "}
          An error-notification is clearly visible on your screen if both modifiers are active.
        </li>
        <li>
          <code>ICON</code>: name of the icon, this can be an icon from{" "}
          <a href="https://fontawesome.com/search?o=r&m=free" target="_blank" rel="noreferrer"
             className="text-indigo-400 hover:text-decoration-underline">fontawesome</a>, {" "}
          <a href="https://www.radix-ui.com/icons" target="_blank" rel="noreferrer"
             className="text-indigo-400 hover:text-decoration-underline">Radix Icons</a>, or{" "}
          <a href="https://mui.com/material-ui/material-icons/" target="_blank" rel="noreferrer"
             className="text-indigo-400 hover:text-decoration-underline">MUI Icons</a>
          <br />
          Examples: <code>fa-user</code>, <code>fa-minus-circle</code>, <code>radix-layers</code>, <code>radix-dots-horizontal</code>, <code>mui-add</code>.
        </li>
        <li>
          <code>ICONCOLOR</code>: css color of the icon. Examples: <code>blue</code>, <code>rgba(128, 128, 0, 0.5)</code>, <code>#ffee33</code>
        </li>
        <li>
          <code>ICONSIZE</code>: css size of the icon. Examples: <code>15px</code>, <code>16pt</code>, <code>2rem</code>
        </li>
      </ul>
    </div>
  )
}

export type TablePlusPlusConfigOptions = ConfigurablePropsFromDescription<typeof configOptions>

function ConfigJsonHelp() {
  const example: ColumnConfig = [
    {
      "id": "user",
      "label": "User",
      "publishTopic": "selectedUser",
      "filterable": false,
      "sortable": true,
      "width": "100px"
    },
    {
      "id": "actions",
      "label": "Actions",
      "width": "150px",
      "buttons": [
        {
          "icon": "fa-star",
          "actions": [
            {
              "type": "start-rule",
              "startRule": "favourite rule",
              "parameters": {
                "someParameter": "{{someValue}}"
              },
              "timestampVariable": "myTimestamp"
            }
          ]
        },
        {
          "buttons": [
            {
              "label": "Open user details",
              "icon": "fa-user",
              "actions": [
                {
                  "type": "publish",
                  "topic": "userForPopup",
                  "value": "{{user}}"
                }
              ]
            }
          ]
        }
      ]
    }
  ]

  return (
    <div>
      <p className="mb-3">
        This JSON allows setting configuration settings per column, such as its label.
        Additionally, you can configure one or more buttons to be displayed in the column's cells. See the example below.
        Note that the buttons are only displayed if the column is not used in the query.
      </p>
      <p className="mb-3">
        <ul className="list-disc list-inside">
          <li>If the column maps to query result variable, set the <code>id</code> to be the same as the query result variable name</li>
          <li>Currently two action types are supported in buttons: <code>"publish"</code> and <code>"start-rule"</code></li>
          <li>You can use query result variables in <code>{"{{placeholders}}"}</code>, from any column</li>
          <li>Create a button without an icon and with button-children to display a "..."-dropdown menu</li>
        </ul>
      </p>
      <p className="mb-3">
        Example:<br />
        <pre style={{color: '#e83e8c'}}>{JSON.stringify(example, null, 4)}</pre>
      </p>
    </div>
)
}