import axios, { isAxiosError } from "axios"
import { OpaqueSparqlResults } from "./sparqlResultHelpers"
import { DeferredPromise } from "./DeferredPromise"

type ResultHolder = {
  result: OpaqueSparqlResults
  isLatest: () => boolean
}

type QueryString = string

type TemplateId = string

export class ErrorNeedAuth extends Error {}

const state = {
  instances: new Map<TemplateId, SparqlTemplate>(),
  cache: new Map<QueryString, OpaqueSparqlResults>()
}

type LastFetchRequest = {
  query: string
  promise: PromiseLike<ResultHolder>
}

export class SparqlTemplate {
  lastRequest: LastFetchRequest | null = null
  sparqlEndpoint: string
  cancelActiveRequest: null | (() => void) = null

  constructor(sparqlEndpoint: string, templateId: TemplateId) {
    this.sparqlEndpoint = sparqlEndpoint
    state.instances.set(templateId, this)
  }

  async fetch(query: QueryString): Promise<ResultHolder> {
    if (this.lastRequest?.query === query) {
      return this.lastRequest.promise
    }

    const promise = new DeferredPromise<ResultHolder>()
    this.lastRequest = { query, promise }

    const isLatest = () => promise === this?.lastRequest?.promise
    this.cancelActiveRequest?.()

    if (state.cache.has(query)) {
      const cachedResults = state.cache.get(query)
      if (!cachedResults) throw new Error('Cache problem!')
      setTimeout(() => promise.resolve({ isLatest, result: cachedResults }), 10)
      return promise
    }

    try {
      const data = new URLSearchParams()
      data.set('infer', String(false))
      data.set('sameAs', String(false))
      data.set('query', query)

      const response = await axios({
        method: 'post',
        url: this.sparqlEndpoint,
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
        data,
        // @ts-ignore
        withCredentials: document.withCredentials ?? true,
        headers:{
          Accept: 'application/sparql-results+json,*/*;q=0.9',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        cancelToken: new axios.CancelToken((c) => this.cancelActiveRequest = c),
      })

      const match = query.match(/#\s*artificially-slow-query\s*=\s*([0-9]+)/)
      if (match) {
        await waitFor(parseInt(match[1]))
      }

      this.cancelActiveRequest = null
      const result = response.data

      if (shouldCacheQuery(query)) {
        state.cache.set(query, result)
      }

      promise.resolve({
        isLatest,
        result
      })
      return promise
    } catch (error) {
      if (isAxiosError(error) && (error.response?.status === 401 || error.response?.status === 403))
        throw new ErrorNeedAuth()
      throw error
    }
  }
}

async function waitFor(ms: number): Promise<void> {
  return new Promise(resolve => {
    setTimeout(() => resolve(), ms)
  })
}

function shouldCacheQuery(query: QueryString) {
  return !query.includes("#nocache")
}
