import { Component } from 'react'
import { Yasgui } from './Yasgui'
import { Input, Label } from 'reactstrap'
import { FormGroup} from './FormGroup'
import AceEditor from 'react-ace'
// import MonacoEditor from 'react-monaco-editor';
// import brace from 'brace';

import 'brace/mode/javascript'
import 'brace/theme/github'
import styles from './FormField.module.css'
import { useDialog } from "../hooks/use-dialog"
import { HelpDialog } from "./HelpDialog"
import { JsonEditor, JsonTextEditor } from "./JsonEditor"
import {mergeClasses} from '../helpers/styling'
import cssFix from './mui/resolutions.module.css'

class FormField extends Component {
  onChange = (e) => {
    let name = this.props.name
    let value = e.target.value
    if (this.props.type === 'array') {
      value = value.split(',')
    }
    if (this.props.type === 'json') {
      try {
        value = JSON.parse(value)
      } catch (e) {
        // poging 2
        value = eval(value) //eslint-disable-line no-eval
        // console.log("poging 2",value);
      }
    }
    this.props.onChange(name, value)
  }

  render() {
    let type = this.props.type
    let default2 = styles.default

    if (type === 'yasgui') {
      return (
        <FormGroup>
          <LabelWithHelp label={this.props.label} helpComponent={this.props.helpComponent} />
          <Yasgui
            mode="init-value"
            value={this.props.value}
            onBlur={(value) => this.props.onChange(this.props.name, value)}
          />
        </FormGroup>
      )
    } else if (type === 'javascript') {
      return (
        <FormGroup>
          <LabelWithHelp label={this.props.label} helpComponent={this.props.helpComponent} />
          <AceEditor
            mode="javascript"
            theme="github"
            style={{ width: '100%', height: '120px' }}
            value={this.props.value}
            onChange={(newValue) => {
              this.props.onChange(this.props.name, newValue)
            }}
            name="editor"
          />
        </FormGroup>
      )
    } else if (type === 'select') {
      return (
        <FormGroup>
          <LabelWithHelp label={this.props.label} helpComponent={this.props.helpComponent} />
          <Input
            classforname="dark-form-control"
            className={mergeClasses(default2, 'dark-form-control', cssFix['dark-form-control-select'])}
            value={this.props.value || ''}
            type="select"
            onChange={this.onChange}
          >
            {this.props.options && this.props.options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>))}
          </Input>
        </FormGroup>
      )
    } else if (type === 'boolean') {
      return (
        <FormGroup>
          <Label style={{ cursor: 'pointer' }}>
            <Input
              checked={this.props.value ?? false}
              className={styles.check}
              type="checkbox"
              onChange={(e) =>
                this.props.onChange(this.props.name, e.target.checked)
              }
            />
            {'  ' + this.props.label}
          </Label>
        </FormGroup>
      )
    } else if (type === 'button') {
      return (
        <FormGroup>
          <Label style={{ cursor: 'pointer' }}>
            <button
              className="btn btn-primary"
              style={{
                'min-width': '120px',
                minHeight: '30px',
                'margin-top': '12px',
                marginLeft: '0',
                marginRight: '6px',
                position: 'relative',
              }}
              onClick={(e) => console.log(e.target, this.props, this)}
            />
            {'  ' + this.props.label}
          </Label>
        </FormGroup>
      )
    } else if (type === 'json') {
      return (
        <FormGroup>
          <LabelWithHelp label={this.props.label} helpComponent={this.props.helpComponent} />
          <JsonEditor name={this.props.name}
                      value={this.props.value}
                      onValueChange={value => {
                        this.props.onChange(this.props.name, value)
                      }} />
        </FormGroup>
      )
    } else if (type === 'json-text') {
      return (
        <FormGroup>
          <LabelWithHelp label={this.props.label} helpComponent={this.props.helpComponent} />
          <JsonTextEditor name={this.props.name}
                          value={this.props.value || ''}
                          onValueChange={value => {
                            this.props.onChange(this.props.name, value)
                          }} />
        </FormGroup>
      )
    } else {
      return (
        <FormGroup>
          <LabelWithHelp label={this.props.label} helpComponent={this.props.helpComponent} topicValueInterpolationEnabled={this.props.topicValueInterpolationEnabled} enabledTopics={this.props.enabledTopics}/>
          <Input
            className="dark-form-control"
            value={this.props.value || ''}
            type={type}
            onChange={this.onChange}
          />
        </FormGroup>
      )
    }
  }
}

function LabelWithHelp(props) {
  const show = useDialog()

  return (
    <>
      <Label style={{ width: '100%', whiteSpace: 'pre-line' }}>
        <div style={{ display: 'flex', gap: '1rem' }}>
          <div style={{ flexGrow: 1 }}>
            {props.label}
          </div>

          {props.topicValueInterpolationEnabled &&(
            <i>
            topic {"{{variables}}"} enabled
            </i>
          )}
          {props.helpComponent && (
            <a href="#" onClick={e => {
              e.preventDefault()
              show(HelpDialog, {
                component: props.helpComponent
              })
            }}>
              Help...
            </a>
          )}
        </div>
      </Label>
    </>
  )
}

export default FormField
