import { PublishedDict } from '../redux/pubsub'

export class NoPlaceholderValueError extends Error {
  constructor(placeholder: string, options?: Parameters<ErrorConstructor>['1']) {
    const message = `Template-placeholder {{${placeholder}}} did not resolve to a value`
    super(message, options)
  }
}

/**
 * (SPARQL-)template strings can contain value-placeholders.
 * These value-placeholders are annotated as `{{placeholder}}`.
 * Each placeholder represent either a published topic-value or a fallback value.
 * They are annotated as follows `{{topic||fallbackTopic::fallbackValue}}`.
 * 
 * This is a replacement for a part of old functionality (18-06-2024).
 * See ./front-end/src/components/Widget.js::resolveTemplateVariableInQuery
 */
export function resolveTemplateString(
  template: string,
  published: PublishedDict,
): string {
  const placeholders = getPlaceholders(template)
  if (!placeholders.length) return template

  let query = template
  for (const placeholder of placeholders) {
    const value = getValue(placeholder, published)
    if (value == null) throw new NoPlaceholderValueError(placeholder)
    query = replacePlaceholderWithValue(query, placeholder, value)
  }
  return query
}

export function resolveTemplateStringAndLogErrors(
  template: string,
  published: PublishedDict,
): string {
  try {
    return resolveTemplateString(template, published)
  } catch (e) {
    console.error(e)
    return template
  }
}

function getPlaceholders(template: string) {
  const subscribePropMatches = template.match(/{{!?[A-Za-z0-9|:./]*}}/g)
  if (!subscribePropMatches) return []
  return [...new Set(subscribePropMatches.map((f) => f.replace(/[{}]/g, '')))]
}

/**
 * The control-flow of this code should be left untouched, until there is a spec for (SPARQL-)templates.
 * In the past, changes to the control-flow have broken Hans' apps, which we don't host.
 */
function getValue(placeholder: string, pubValue: PublishedDict) {
  let value = pubValue[placeholder]

  if (placeholder.includes('||')) {
    const topic = placeholder.split('||')[0]
    value = pubValue[topic]
    if (value === 'http://www.buildingbits.nl/reset') value = null

    if (value == null) {
      const topic = placeholder.split('||')[1].split('||')[0].split('::')[0]
      value = pubValue[topic]
      if (value === 'http://www.buildingbits.nl/reset') value = null
    }
  }

  if (placeholder.includes('::') && value == null) {
    const topic = placeholder.split('::')[0]
    value = pubValue[topic]
    if (value === 'http://www.buildingbits.nl/reset') value = null
    if (value == null) value = placeholder.split('::')[1]
  }

  const negatedTopicPattern = /^!(.+)$/
  const negatedTopic = placeholder.match(negatedTopicPattern)?.[1]
  if (negatedTopic) value = !(String(pubValue[negatedTopic]).toLowerCase() === 'true')

  return value
}

function replacePlaceholderWithValue(template: string, placeholder: string, value: string) {
  const placeholderPattern = new RegExp(`{{${placeholder.replaceAll('||', '\\|\\|')}}}`, 'g')
  return template.replace(placeholderPattern, value)
}
